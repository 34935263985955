<template>

      <div>
		  <div>
		  		 <img src="../../assets/img/logo.png" class="logo" />
		  </div> 
		   <el-form ref="form" :model="form"  id="selectForm">
			 <el-form-item>
			   <div class="formItem">编号:</div>
			   <el-input v-model="form.num" disabled="true"></el-input>
			 </el-form-item>  
		     <el-form-item >
				<div class="formItem">推荐人:</div> 
		       <el-input v-model="form.username" disabled="true"></el-input>
		     </el-form-item>
			 <el-form-item>
				 <div class="formItem">推荐人生活馆会员号:</div>
			   <el-input v-model="form.m_id"  disabled="true"></el-input>
			 </el-form-item>
			 <el-form-item>
				 <div class="formItem">顾客名字:</div>
			   <el-input v-model="form.cust_name"  disabled="true"></el-input>
			 </el-form-item>
			 <el-form-item >
				 <div class="formItem">顾客生活馆会员号:</div>
			   <el-input v-model="form.cust_id"  disabled="true"></el-input>
			 </el-form-item>
			 <el-form-item >
				 <div class="formItem">顾客电话:</div>
			   <el-input v-model="form.cust_phone"  disabled="true"></el-input>
			 </el-form-item>
			 <el-form-item >
				 <div class="formItem">顾客地址:</div>
			    <el-input  type="textarea" rows="3" v-model="form.cust_address"   disabled="true"></el-input>
			 </el-form-item>
			 <el-form-item >
				<div class="formItem">截图凭证:</div>
			   <img :src="form.credent" class="avatar">
			 </el-form-item>
			  <el-button type="primary"  @click="onList">查看历史提交记录</el-button>
		   </el-form>
		   <div class="footer">
		   		  			   <div>所填资料确保真实有效，一切解释权为公司所有</div>
		   					   <div style="margin-top: 10px;">
		   					   		<img src="../../assets/img/logo2.png" class="logo" style="width: 200px;" />
		   					   </div> 
		   </div>
	  </div>
</template>

<script>
    import { modelTrainlist, messageDetail } from '@/api/modelTrain/user'
    export default {
        data() {
            return {
                list: [],
				form: {
					num:'',
					username: '',
					m_id: '',
					cust_name: '',
					cust_id: '',
					cust_phone: '',
					cust_address: '',
				}
            }
        },
        watch:{

        },
        computed: {

        },
        created() {
          
        },
		mounted() {
			this.getData()
		},
        methods: {
			onList(){
								this.$router.push({name: 'list',})
			},
            getData(){
				 let ms_id=this.$route.params.msId
				 console.log(ms_id)
				 messageDetail(ms_id).then(res=>{
					  this.form.num=res.data.num
					  this.form.username=res.data.username
					  this.form.m_id=res.data.m_id
					  this.form.cust_name=res.data.cust_name
					  this.form.cust_id=res.data.cust_id
					  this.form.cust_phone=res.data.cust_phone
					  this.form.cust_address=res.data.cust_address
					  this.form.credent=res.data.credent
				 })
			}
        }
    }
</script>

<style scoped lang="scss">
	.icon{
		display: inline-block;
		color: red;
	}
	.formItem{
		    width: 80%;
		    text-align: left;
		    margin: auto;
		    margin-bottom: 10px;
	}
   #selectForm >>> .el-input{
	   width: 80%;
   }
   #selectForm >>> .el-textarea__inner{
   	  width:80%;
   	  margin: auto;
   }
   #selectForm >>> .el-form-item__label {
     font-size: 12px;
   }
   #selectForm >>> .el-form-item__content { 
     line-height: normal;
   }
   #selectForm >>> .el-form-item{
	   margin-bottom: 10px;
   }
   .logo{
	   width: 150px;
	   object-fit:cover
   }
     .avatar {
       width: 78px;
       height: 78px;
       display: block;
	   margin: auto;
     }
	   .avatar-uploader .el-upload {
	     border: 1px dashed #d9d9d9;
	     border-radius: 6px;
	     cursor: pointer;
	     position: relative;
	     overflow: hidden;
	   }
	   .avatar-uploader .el-upload:hover {
	     border-color: #409EFF;
	   }
	   .avatar-uploader-icon {
	     font-size: 28px;
	     color: #8c939d;
	     width: 78px;
	     height: 78px;
	     line-height: 78px;
	     text-align: center;
	   }
	   .footer{
		   margin-top: 20px;
		   padding-bottom: constant(safe-area-inset-bottom);
		   padding-bottom: env(safe-area-inset-bottom);
		   /*
		  position: fixed;
		  bottom: 0;
		  left: 0;
		  width: 100%;
		  height: 40px;
		  background: #fff;
		  padding-bottom: constant(safe-area-inset-bottom); 
		  padding-bottom: env(safe-area-inset-bottom);
		  */
	   }
</style>