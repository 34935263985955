import request from '@/utils/request'

//code 授权
export function getUser(data) {
    return request({
        url: '/product/mini/product/callBack',
        method: 'get',
        params: {
          code: data.code,
		  script:data.script
        }
    })
}
//填写资料
export function addMessage(data) {
    return request({
        url: '/product/mini/product/feedback',
        method: 'post',
        params: {
          id: data.id,
          text:data.text,
		  pictureLists:data.pictureLists
        }
    })
}

//资料列表
export function messageList(data) {
    return request({
        url: '/api/love/messagelist',
        method: 'post',
        data
    })
}


//资料详情
export function messageDetail(data) {
    return request({
        url: '/api/love/message',
        method: 'get',
        params: {
          ms_id:data
        }
    })
}